import React from 'react'
import PropTypes from 'prop-types'

const UserBadge = props => {
    const getClassName = () => {
        let common = "badge rounded-pill text-dark m-0 align-middle "
        if (props.online){
            return common + "bg-success"
        } else {
            return common + "bg-secondary"
        }
    }
    return (
        <div className="mx-0 px-1 col-auto align-middle">
            <span className={getClassName()}>{props.name}</span>
        </div>
    )
}

UserBadge.propTypes = {
    name: PropTypes.string,
    online: PropTypes.bool,
}

export default UserBadge
