import React from 'react'
import PropTypes from 'prop-types'
import UserBadge from './UserBadge'

const UsersLine = props => {
    return (
        <div className="d-flex flex-row row mb-1">
            <span className="col-auto">Online: </span>
            {
                props.users_info.online_users.map((name, index) => <UserBadge key={index} name={name} online={true}/>)
            }
            | Offline: 
            {
                props.users_info.offline_users.map((name, index) => <UserBadge key={index} name={name} online={false}/>)
            }
        </div>
    )
}

UsersLine.propTypes = {
    users_info: PropTypes.object
}

UsersLine.defaultProps = {
    users_info: {
        all_users: [],
        online_users: [],
        offline_users: [],
    },
}

export default UsersLine
