import React from 'react'
import PropTypes from 'prop-types'
import SingleIP from './SingleIP'
const IP = props => {
    return (
        <div className="d-flex flex-row row mb-1">
            <span className="col-auto">Private IP Address: </span>
            {
                props.data.map((ip, index) => {
                    if (ip[0] === 'lo' || ip[0] === 'docker0' || ip[0] === 'tailscale0') {
                        return null;
                    }
                    else {
                        return (
                            <SingleIP key={index} name={ip[0]} ip={ip[1]} />
                        )
                    }
                }

                )
            }
        </div>
    )
}

IP.propTypes = {
    data: PropTypes.array
}

IP.defaultProps = {
    data: []
}

export default IP
