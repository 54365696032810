import React from 'react'

const DisplayPercent = (props) => {
    const colorCode = () => {
        if (props.percent < 0.7) {
            return "text-info"
        } else if (props.percent >= 0.7 && props.percent < 0.9) {
            return "text-warning"
        } else if (props.percent >= 0.9 && props.percent <= 1.0) {
            return "text-danger"
        } else {
            return "text-secondary"
        }
    }
    return (
        <span className={colorCode()}>
            {(props.percent * 100).toFixed(1)}%
        </span>
    )
}

export default DisplayPercent
