import React from 'react'
import PropTypes from 'prop-types'
import DisplayPercent from './DisplayPercent'
import DisplayTemp from './DisplayTemp'
import DisplayRAM from './DisplayRAM'

const GPUv2 = props => {
    return (
        <div className="my-2 px-2 py-2 card bg-dark">
            <p className="py-0 mb-1 card-header">
                <span className="badge fw-light text-secondary">GPU: </span>
                <span className="badge p-1 bg-secondary text-dark align-middle">{props.data.index}</span>
                <span className="badge fw-light text-secondary">{props.data.gpu_name}</span>
            </p>
            <div className="card-body pb-0 pt-2">
                <p className="mb-1">Core Util: <span className="badge fw-light fs-6"><DisplayPercent percent={props.data.gpu_usage} /></span> Core Temp: <span className="badge fw-light fs-6"> <DisplayTemp temp={props.data.temperature} /> </span></p>
                <p className="mb-1">
                    Memory Util: <span className="badge fw-light fs-6"><DisplayPercent percent={props.data.memory_usage} /></span>
                    (free:<span className="badge fw-light fs-6 text-secondary"><DisplayRAM ram={props.data.memory_free} /></span> total:<span className="badge fw-light fs-6 text-secondary"><DisplayRAM ram={props.data.memory_total} /></span>)
                </p>
            </div>
        </div>
    )
}

GPUv2.propTypes = {
    "data": PropTypes.object
}

GPUv2.defaultProps = {
    "data": {
        "index": 0,
        "gpu_name": "GeForce RTX xxxx xx",
        "gpu_usage": 0.0,
        "temperature": 0.0,
        "memory_total": 0.0,
        "memory_free": 0.0,
        "memory_usage": 0.0,
    }
}

export default GPUv2
