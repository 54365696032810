import React from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import IP from './IP'
import GPUv2 from './GPUv2'
import UsersLine from './UsersLine'
import DisplayPercent from './DisplayPercent'
import DisplayRAM from './DisplayRAM'

const WorkstationV2 = props => {


    const getTimestamp = () => {
        let v = props.data.created_at
        let options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }

        if (typeof v === 'string' || v instanceof String) {
            let d = new Date(v)
            return d.toLocaleTimeString("en-GB", options)
        } else {
            return "N.A."
        }
    }

    const isOnline = () => {
        let v = props.data.created_at
        let now = new Date()

        if (typeof v === 'string' || v instanceof String) {
            let d = new Date(v)
            let i = now - d
            // 2 Minutes = 120000 Milliseconds
            if (i <= 120000) {
                // console.log("is online")
                return true
            }
        }

        return false
    }

    const singleLine = (s) => {
        // keep string to single line
        // remove all newlines
        // remove all tabs
        // remove all leading and trailing spaces
        return s.replace(/(\r\n|\n|\r)/gm, " ").replace(/\t/g, ' ').trim()
    }

    const [showDetails, SetShowDetails] = useState(false)
    const handleShowDetails = () => {
        SetShowDetails((state) => {
            if (state) {
                return false
            } else {
                return true
            }
        })
    }

    const isEmpty = (s) => {
        if (s == null || s === undefined || s === "" || s.length === 0) {
            return true
        } else {
            return false
        }
    }

    const getPortFromName = (name) => {

        let PORT_MAP = {
            alan: 2001,
            maurice: 2002,
            richard: 2003,
            marvin: 2004,
            james: 2005,
            john: 2006,
            dijkstra: 2007,
            charles: 2008,
            donald: 2009,
            newell: 2010,
            simon: 2011,
            michael: 2012,
            dana: 2013,
            backus: 2014,
            robert: 2015,
            kenneth: 2016,
            tony: 2017,
            edgar: 2018,
            stephen: 2019,
            ken: 2020,
            dennis: 2099,
        }

        if (name == null || name === undefined || name === "" || name.length === 0) {
            return "N.A."
        } else if (PORT_MAP[name] === undefined) {
            return "N.A."
        } else {
            return PORT_MAP[name]
        }
    }

    const getNameFromHostname = (hostname) => {
        // usually the hostname is in the form of sutd-vlg-<name>
        if (hostname == null || hostname === undefined || hostname === "" || hostname.length === 0) {
            return "N.A."
        }
        if (hostname.startsWith("sutd-vlg-") === false) {
            return hostname
        } else {
            let name = hostname.split('-').pop()
            return name
        }
    }

    const thisName = getNameFromHostname(props.data.hostname)
    const capName = thisName.charAt(0).toUpperCase() + thisName.slice(1)
    const thisPort = getPortFromName(thisName)


    return (
        // <div className={isOnline() ? "text-success" : "text-secondary"} style={{ fontFamily: "Source Code Pro" }}>
        <div className={isOnline() ? "text-success" : "text-secondary"} style={{ fontFamily: "Source Code Pro" }}>

            <p className="mt-2 mb-1">
                {/* <span className="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span> */}
                <span className="fs-3">{capName} </span>
                <span className="fs-6 fw-light">(-p {thisPort}) </span>
                {/* <span className="p-1 m-1 align-middle  badge bg-secondary text-dark user-select-all">{props.data.hostname}</span> */}
                {
                    isOnline() ?
                        <span className="p-1 m-1 align-middle badge bg-success text-dark user-select-none">online</span>
                        :
                        <span className="p-1 m-1 align-middle badge bg-danger user-select-none">offline</span>
                }
                <span className="p-1 m-1 align-middle badge btn btn-outline-secondary text-light fw-light user-select-none" onClick={handleShowDetails}>details</span>

            </p>
            {
                showDetails ?
                    <>
                        <p className="mb-1">
                            Lase seen:<span className="badge fw-light fs-6 text-secondary">{getTimestamp()}</span> Uptime:<span className="badge fw-light fs-6 text-secondary">{props.data.uptime_str}</span>
                        </p>
                        <p className="mb-1">Hostname: <span className="badge fw-light fs-6 text-secondary">{props.data.hostname}</span></p>
                        <p className="mb-1">Arch: <span className="badge fw-light fs-6 text-secondary">{props.data.architecture}</span> System: <span className="badge fw-light fs-6 text-secondary">{props.data.linux_distro}</span></p>
                        <p className="mb-1">CPU Model: <span className="badge fw-light fs-6 text-secondary">{props.data.cpu_model}</span></p>
                        {/* <p className="mb-1">Public IP: <span className="badge fw-light fs-6 text-secondary">{props.data.public_ip}</span> MAC Address: <span className="badge fw-light fs-6 text-secondary">{props.data.mac_address}</span></p> */}
                        <IP data={props.data.ipv4s} />
                        <UsersLine users_info={props.data.users_info} />
                    </> : null
            }

            {showDetails ? <>
                <p className="mb-1">CPU Util: <span className="badge fw-light fs-6"><DisplayPercent percent={props.data.cpu_usage} /></span></p>
                <p className="mb-1">RAM Util: <span className="badge fw-light fs-6"><DisplayPercent percent={props.data.ram_usage} /></span>
                    (free:<span className="badge fw-light fs-6 text-secondary"><DisplayRAM ram={props.data.ram_free} /></span>
                    total:<span className="badge fw-light fs-6 text-secondary"><DisplayRAM ram={props.data.ram_total} /></span>)
                </p></> : null
            }



            {
                isEmpty(props.data.gpu_status) || !showDetails ? null :
                    props.data.gpu_status.map((gpu_data, idx) => <GPUv2 key={idx} data={gpu_data} />)
            }

            {
                isEmpty(props.data.gpu_compute_processes) || showDetails ? null :
                    <ol className='text-secondary ml-28'>
                        {
                            props.data.gpu_compute_processes.map((process, idx) =>
                                <li className='' key={idx}>
                                    {process.user} - <span className='text-info'>GPU {process.gpu_index}</span> - <span className=''>{process.proc_uptime_str}</span>
                                </li>

                            )
                        }
                    </ol>
            }

            {
                isEmpty(props.data.gpu_compute_processes) || !showDetails ? null :
                    <table className='container table table-sm table-dark text-center text-success'>
                        <thead>
                            <tr className='align-middle text-muted'>
                                <th scope="col" className="fw-light fs-7">GPU</th>
                                <th scope="col" className="fw-light fs-7">PID</th>
                                <th scope="col" className="fw-light fs-7">User</th>
                                <th scope="col" className="fw-light fs-7">UpTime</th>
                                <th scope="col" className="fw-light fs-7">CMD</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.data.gpu_compute_processes.map((process, idx) =>
                                    <tr className='align-middle' key={idx}>
                                        <td className="fw-light fs-7">{process.gpu_index}</td>
                                        <td className="fw-light fs-7">{process.pid}</td>
                                        <td className="fw-light fs-7">{process.user}</td>
                                        <td className="fw-light fs-7">{process.proc_uptime_str}</td>
                                        <td className="fw-light fs-7 overflow-hidden">{singleLine(process.command)}</td>
                                    </tr>

                                )
                            }
                        </tbody>
                    </table>
            }

            <hr className="mt-4 text-white" />

        </div >
    )
}

WorkstationV2.propTypes = {
    "data": PropTypes.object,
}

WorkstationV2.defaultProps = {
    "data": {
        "gpu_status": []
    }
}

export default WorkstationV2
