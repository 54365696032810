import React from 'react'

const Footer = () => {
    return (
        <footer className="mt-5 footer">
            <div className="container">
                <div className="py-5 d-flex flex-row justify-content-center text-muted user-select-none badge" style={{ fontFamily: "Montserrat" }}>
                    <p className="me-1">Made with</p>
                    <p className="me-1 heart">❤️</p>
                    <p className="me-1">by</p>
                    <a className="me-1 text-muted" href="https://markhh.com" target="_blank" rel="noopener noreferrer" style={{ "textDecoration": "none" }}>Mark</a>
                    <p className="ms-1 me-2"> | </p>
                    <p className="me-1">Powered by</p>
                    {/* <p className="ms-1 me-2"> | </p> */}
                    <a className="mt-0 me-1 text-muted" href={"https://github.com/mxshell/MXStatus"} target="_blank" rel="noopener noreferrer" style={{ "textDecoration": "none" }}>MXShell</a>
                </div>
            </div>
        </footer>
    )
}

export default Footer
