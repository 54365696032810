import React from 'react'

const DisplayRAM = (props) => {
    return (
        <span className={""}>
            {(props.ram / 1024).toFixed(1)} GiB
        </span>
    )
}

export default DisplayRAM
