import React from 'react'
import PropTypes from 'prop-types'

const SingleIP = props => {
    return (
        <div className="mx-0 px-1 col-auto align-middle">
            <span className="badge bg-secondary text-dark p-0 ps-1 align-middle">{props.name} <span className="badge bg-info text-dark align-middle user-select-all">{props.ip}</span></span>
        </div>
    )
}

SingleIP.propTypes = {
    "name": PropTypes.string,
    "ip": PropTypes.string,
}

SingleIP.defaultProps = {
    "name": "xx",
    "ip": "xxx.xxx.xxx.xxx",
}

export default SingleIP
