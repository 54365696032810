import React from 'react'

const DisplayTemp = (props) => {
    const colorCode = () => {
        if (props.temp < props.warningTemp) {
            return "text-info"
        } else if (props.temp >= props.warningTemp && props.temp < props.dangerTemp) {
            return "text-warning"
        } else if (props.temp >= props.dangerTemp) {
            return "text-danger"
        } else {
            return "text-secondary"
        }
    }
    return (
        <span className={colorCode()}>
            {props.temp}°C
        </span>
    )
}

DisplayTemp.defaultProps = {
    "temp": 0.0,
    "warningTemp": 80,
    "dangerTemp": 85,
}
export default DisplayTemp
