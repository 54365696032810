import './App.css';
import PageMain from "./components/PageMain"
import Footer from "./components/Footer"

function App() {
  return (
    <div className="App">
      <PageMain />
      <Footer />
    </div>
  );
}

export default App;